<template>
  <!-- 课程表 -->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" :model="queryParams">
        <el-form-item v-if="chief || manage">
        <!-- <el-form-item v-if="chief || manage" class="area_img"> -->
          <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item>
          <el-input size="small" v-model="queryParams.course" clearable placeholder="请输入用户姓名"></el-input>
          <!-- <el-input v-model="queryParams.course" clearable placeholder="请输入用户姓名" @change="resetPageAndRefresh"></el-input> -->
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
      </el-form>
      
      <!-- 列表 -->
      <div v-if="storeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="storeList" :total="storeTotal" :span='12'  @refresh="refresh">
        <template slot-scope="scope">
          <div class="list">
            <div class="con-head">
              <div class="area" v-if="scope.row.extend">
                <p class="con-txt">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                <p class="mission">学校名称</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ scope.row.realName?scope.row.realName:'-' }}</p>
                <p class="mission">用户姓名</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ scope.row.account?scope.row.account:'-' }}</p>
                <p class="mission">学号</p>
              </div>
              <div class="area fr">
                <p class="con-txt">{{ scope.row.semesterName?scope.row.semesterName:'-' }}</p>
                <p class="mission">学期</p>
              </div>
              <div class="areas fr">
                <p class="con-txt">{{ scope.row.schoolYear?scope.row.schoolYear: '-' }}</p>
                <p class="mission">学年</p>
              </div> 
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">课程名</span>
                  <span class="condel">{{ scope.row.course }}</span>
                </div>
                <div class="time">
                  <span class="contit">教室</span>
                  <span class="condel">{{ scope.row.classroom }}</span>
                </div>
                <div class="time">
                  <span class="contit">周次</span>
                  <span class="condel">{{ scope.row.week }}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time">
                  <span class="contit">教师</span>
                  <span class="condel">{{ scope.row.teacher }}</span>
                </div>
                <div class="time">
                  <span class="contit">周几</span>
                  <span class="condel">{{ scope.row.weekday }}</span>
                </div>
                <div class="time">
                  <span class="contit">节次</span>
                  <span class="condel">{{ scope.row.section }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle></el-button>
          <el-button type="danger" icon="el-icon-delete" circle></el-button>
        </template>
      </paged-list>
    </div>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import { mapState, mapActions } from "vuex";
/* eslint-disable no-unused-vars */
export default {
  name:'timeTable',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      total: 0,
      member: false, ceo: false, manage: false, chief: true,
      queryParams: {
        course: ''
      },
      pageData:[],
      statusOptions: [],
      pageApi:'courseList',
    };
  },
  computed: {
    ...mapState("timeTable", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("timeTable", ["courseList"]),
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
  },
  mounted() {
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
   .select{
      .el-form-item { margin-bottom: 17px; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    // .params-wrap { padding: 0;
    //   .area_img {
    //     /deep/.el-input__inner { padding-left: 30px !important; }
    //     .area_select { position: absolute; z-index: 10; top: 13px; left: 8px; }
    //   }
    //   .el-form-item { margin: 0 20px 0 0; }
    // }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 240px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}
</style>